import React, { PureComponent } from 'react'
import DateTimePicker from 'react-datetime-picker'
import Context from './context'

export default function datetimeCal(props) {

    return (
        <Context.Consumer>

            {
                ctx => {
                    return (
                        <div>
                            <DateTimePicker
                                style={{
                                    width: "291px"
                                }}
                                onChange={props.onChangeMethod}
                                value={props.val}
                            />
                        </div>
                    )
                }
            }
        </Context.Consumer>
    )

}
