import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),

    },
  },
}));

export default function OutlinedButtons(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button style={{ fontFamily: 'Cairo' }} variant="outlined" color="primary" onClick={props.whenClick}>
        {props.label}
      </Button>

    </div>
  );
}