import React from 'react';
import logo from './logo.svg';
import { BrowserRouter, Route, Switch, Router } from 'react-router-dom';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import './App.css';
import Context from './components/context'
import MyNav from './components/MyNav'
import StartPage from './components/StartPage'
const fileJSON = require('./assets/files/constData.json')
/* global wialon */

const RES_NAME = fileJSON.resourceName;
const TARGET_TYPES = fileJSON.targetTypes;
class App extends React.Component {

  constructor(props) {
    super(props)
    let startDate = new Date()
    startDate.setHours(0, 0, 0, 0)
    let endDate = new Date()
    endDate.setHours(23, 59, 59, 59)
   
    
    this.state = {
      token: '', // test app
      isAuthorized: false,
      username: '',
      res: [],
      logging: true,
      showAlert: false,
      alertVariant: 'success',
      error: false,
      alertMSG: '',
      resultJSON: [],
      selectedRes: {},
      selectedReport: {},
      selectedObject: {},
      units: [],
      unitGroups: [],
      executing: false,
      exporting:false,
      fromDateVal: startDate,
      toDateVal: endDate,
      fromDateValUnix: this.convertSATToUnix(startDate),
      toDateValUnix: this.convertSATToUnix(endDate),
      targetTypes: TARGET_TYPES,
      selectedTargetType: fileJSON.targetTypes[0],
      colData: [],
      newData: [], // for unit [{},{},....] , for group [[{},{},....],[],[]] 
      columns: [],
      encodedUri: '',
      download: '',
      overlayText : 'جاري تسجيل الدخول...',
      loginMode:fileJSON.loginMode,
      isTried : false
    }
    this.fromDateOnChange = this.fromDateOnChange.bind(this)
    this.toDateOnChange = this.toDateOnChange.bind(this)
    this.propOpenFunc = this.propOpenFunc.bind(this)
    this.execute = this.execute.bind(this)
    this.onTargetTypeChange = this.onTargetTypeChange.bind(this)
    this.onUnitChange = this.onUnitChange.bind(this)
    this.onUnitGroupChange = this.onUnitGroupChange.bind(this)
    this.export = this.export.bind(this)
    this.setAuthHash = this.setAuthHash.bind(this)
    this.start = this.start.bind(this)
    this.auth1 = this.auth1.bind(this)
  }

  render() {
 
    return (
      <BrowserRouter basename="/">
        <Context.Provider
          value={{
            value: this.state,
            actions: {
              fromDateOnChange: this.fromDateOnChange,
              toDateOnChange: this.toDateOnChange,
              propOpen: this.propOpenFunc,
              execute: this.execute,
              onTargetTypeChange: this.onTargetTypeChange,
              onUnitChange: this.onUnitChange,
              onUnitGroupChange: this.onUnitGroupChange,
              export: this.export,
              setAuthHash : this.setAuthHash,
              start : this.start,
              auth1:this.auth1
            }
          }}>

          <Switch>
          <Route path='/start' exact component={MyNav} /> 
          <Route path='/' component={MyNav} />
           

          </Switch>

        </Context.Provider>
      </BrowserRouter>
    )

  }
  async start(e)
  {
    //console.log('hash updated now... going to /')
    //await this.setState({authHash:hash})
    window.location.href = "/?authHash=09fe3bad5c5671d8c13b9b21bfc6e787";
    
  }

  async componentDidMount() {
   // this.auth()
  }

  setAuthHash(aHashVal)
  {

    console.log('h updated')
    this.setState({authHash:aHashVal})
    console.log('s c')
  }
  async auth1(e)
  {
    console.log('passed hash')
    console.log(e)
   
    this.auth(e)
  }
  async auth(hashOrToken) {
    //await this.setState({ token: JSON.parse(localStorage.getItem('wenk_helper_token')), logging: true })
    wialon.core.Session.getInstance().initSession("https://hst-api.wialon.com");
    // Try to login when component mount
   if(this.state.loginMode === 'token')
   {
     console.log('mode : T')
    this.loginWithToken()
   }
   if(this.state.loginMode === 'authHash')
   {
     console.log('mode : H')
     this.loginWithHash(hashOrToken)
   }
    
  }
  loginWithToken()
  {
    let that = this
    wialon.core.Session.getInstance().initSession("https://hst-api.wialon.com");

    wialon.core.Session.getInstance().loginToken(
      this.state.token,
      "", // try to login
      async code => {
        // login callback
        // if error code - print error message
        this.setState({isTried : true})
        if (code) {
        
          that.setState({ showAlert:true,alertVariant: 'error', logging: false, isAuthorized: false, alertMSG: true, error: true, alertMSG: code === 4? "دخول غير مخول"  :wialon.core.Errors.getErrorText(code) })
          return;
        }
        console.log('authorized')
        await that.setState({
          alertVariant: 'success',
          overlayText:'جاري تحميل البيانات....',
          logging: false,
          executing:false,
          error: false,
          showAlert: true,
          isAuthorized: true,
          alertMSG: wialon.core.Session.getInstance().getCurrUser().getName() + ' : تم تسجيل الدخول بنجاح!',
          username: wialon.core.Session.getInstance().getCurrUser(),
          isTried : true
        })

        await that.init()
      }
    );
  }
  loginWithHash(hash)
  {
    wialon.core.Session.getInstance().initSession("https://hst-api.wialon.com");

    let that = this
    wialon.core.Session.getInstance().loginAuthHash(
      hash,
      "", // try to login
      async code => {
        this.setState({isTried : true})

        // login callback
        // if error code - print error message
        if (code) {
          that.setState({showAlert:true, alertVariant: 'error', logging: false, isAuthorized: false, alertMSG: true, error: true, alertMSG: wialon.core.Errors.getErrorText(code) })
          return;
        }
        that.setState({
          alertVariant: 'success',
          overlayText:'جاري تحميل البيانات....',
          logging: false,
          executing:false,
          error: false,
          showAlert: true,
          isAuthorized: true,
          alertMSG: wialon.core.Session.getInstance().getCurrUser().getName() + ' : تم تسجيل الدخول بنجاح!',
          username: wialon.core.Session.getInstance().getCurrUser(),
          isTried:true
        })

        await that.init()
      }
    );
  }

  init() {
    console.log('start init')
    let that = this
    var sess = wialon.core.Session.getInstance();

    sess.loadLibrary("resourceReports");


    // flags to specify what kind of data should be returned
    let res_flags = wialon.item.Item.dataFlag.base | wialon.item.Resource.dataFlag.reports// 64 bit OR
    let unit_flags = 1;
    let unitGroup_flags = 1;

    sess.updateDataFlags( // load items to current session
      [
        { type: "type", data: "avl_resource", flags: res_flags, mode: 0 },
        { type: "type", data: "avl_unit_group", flags: unit_flags, mode: 0 },
        { type: "type", data: "avl_unit", flags: unitGroup_flags, mode: 0 }

      ], // Items specification

      function (code) { // updateDataFlags callback 
        if (code) {
          // console.log("Error: " + wialon.core.Errors.getErrorText(code));
          return; // exit if error code 
        }

        const resourcesArr = sess.getItems("avl_resource"); // get loaded 'avl_resource's items
        that.setState({ res: resourcesArr })
        that.findResource(resourcesArr)

        const unitGroups = sess.getItems("avl_unit_group");
        const units = sess.getItems("avl_unit");
        console.log(units)
        console.log(unitGroups)
        if (unitGroups.length === 0 && units.length === 0) {
          that.setState({ showAlert: true, error: true, alertMSG: 'لا توجد مجموعات او مركبات!', alertVariant: 'error' })
          return;
        }

        that.setState({ units: units, unitGroups: unitGroups ,overlayText:''});


      });
  }

  findResource(res) {
    console.log(res)
    for (let i = 0; i < res.length; i++) {
      if (res[i].$$user_name === RES_NAME) {
        console.log('res found')
        this.setState({ selectedRes: res[i] })
        let that = this
        for (let [key, value] of Object.entries(res[i].$$user_reports)) {
          console.log(that.state.selectedTargetType.reportName)
          if (value.n === that.state.selectedTargetType.reportName) {
            console.log('report catched : ' + value.n)
            that.setState({ selectedReport: value })
            break;
          }
        }
        break;
      }
    }
  }
  execute(e) {
    if (Object.entries(this.state.selectedRes).length === 0 && this.state.selectedRes.constructor === Object) {

      this.setState({ showAlert: true, error: true, alertMSG: 'يرجى اختيار المصدر', alertVariant: 'error' })
      return;
    }
    if (Object.entries(this.state.selectedReport).length === 0 && this.state.selectedReport.constructor === Object) {

      this.setState({ showAlert: true, error: true, alertMSG: 'يرجى اختيار التقرير', alertVariant: 'error' })
      return;
    }
    if (Object.entries(this.state.selectedObject).length === 0 && this.state.selectedObject.constructor === Object) {

      this.setState({ showAlert: true, error: true, alertMSG: 'يرجى اختيار الهدف', alertVariant: 'error' })
      return;
    }
    if (this.state.fromDateValUnix === '' || this.state.toDateValUnix === '') {
      this.setState({ showAlert: true, error: true, alertMSG: ' يرجى اختيار الفترة اولاً', alertVariant: 'error' })
      return;
    }
    console.log(this.state.fromDateValUnix + ',' + this.state.toDateValUnix)

    console.log(this.state.selectedRes)
    console.log(this.state.selectedReport)
    console.log(this.state.selectedObject)



    let interval = {
      from: this.state.fromDateValUnix,
      to: this.state.toDateValUnix,
      flags: wialon.item.MReport.intervalFlag.absolute
    }
    let that = this
    let sess = wialon.core.Session.getInstance();
    let resou = sess.getItem(this.state.selectedRes._id);
    let template = resou.getReport(this.state.selectedReport.id)

    // first i need to set local datetime 
    this.setState({executing:true,overlayText:'جاري تنفيذ التقرير... يرجى الانتظار... قد تستغرف عملية التنفيذ اكثر من 3 دقائق اذا كانت الفترة المحددة طويلة'})
    let localObj = {
      flags: 0,
      formatDate: "%25Y-%25m-%25E %25H:%25M:%25S"

    }
    sess.getRenderer().setLocale(134228528, 'en', localObj, function (code) {
      if (code) {
        that.setState({ showAlert: true, error: true, alertMSG: 'الوقت غير متزامن!', alertVariant: 'error' })
        return;
      }
      resou.execReport(template, that.state.selectedObject._id, 0, interval, function (code, data) {
        if (code) {
          that.setState({ showAlert: true, error: true, alertMSG: wialon.core.Errors.getErrorText(code), alertVariant: 'error' })
          return;
        }
        if (!data.getTables().length) {
          that.setState({ showAlert: true, error: true, alertMSG: 'جدول البيانات فارغ, لا توجد بيانات', alertVariant: 'error' })
          return;
        }
    that.setState({overlayText:'جاري بناء التقرير'})

        that.fetchTableData(data)
      });
    })


  }
  fetchTableData(data) {
    let table = data.getTables()[0];
    let obj = {
      type: "range",
      data: {
        from: 0,
        to: table.rows,
        level: 5,
        flat: 0,
        rawValues: 0
      }
    }
    let that = this
    data.selectRows(0, obj, function (code2, col) {
      if (code2) {
        that.setState({ showAlert: true, error: true, alertMSG: wialon.core.Errors.getErrorText(code2), alertVariant: 'error' })
        return;
      }
      console.log('col data now is ')
      console.log(col)
      that.setState({ showAlert: false, error: false, alertMSG: '', alertVariant: 'success', colData: col })
      //that.buildFinalData(col)

      var d = new Date(that.state.fromDateValUnix * 1000);
      let strDate = d.toLocaleDateString()

      let month = strDate.split('/')[0]
      let year = strDate.split('/')[2]
      console.log('month is : ' + month + ' , year is : ' + year)
      that.calcCol(month, year)
      that.buildFinalData(col)
    });
  }

  fromDateOnChange(e) {

    let from = this.convertSATToUnix(e)
    if (!this.state.fromDateVal === '') {

      if (e) {
        if (this.compareTime(from, this.state.toDateValUnix)) {

          this.setState({ fromDateValUnix: from, fromDateVal: e })

        }
        else {
          this.setState({ showAlert: true, error: true, alertMSG: 'يجب ان تكون الفترة المحددة  صحيحة' })
        }

      }
    }
    else {
      this.setState({ fromDateValUnix: from, fromDateVal: e })

    }



  }
  toDateOnChange(e) {

    let to = this.convertSATToUnix(e)
    if (e) {
      if (this.compareTime(this.state.fromDateValUnix, to)) {

        this.setState({ toDateValUnix: to, toDateVal: e })

      }
      else {
        this.setState({ showAlert: true, error: true, alertMSG: 'يجب ان تكون الفترة المحددة  صحيحة' })
      }

    }

  }
  convertSATToUnix(sat) {
    let longVal = Math.floor(new Date(sat).getTime() / 1000)
    return longVal
  }
  compareTime(from, to) {
    return from <= to
  }
  propOpenFunc(e) {
    this.setState({ showAlert: !this.state.showAlert, alertMSG: '', error: false })
  }

  async onTargetTypeChange(e) {
    if (e) {
      let selObj = e.id === 0 ? this.state.units.length > 0 ? this.state.units[0] : {}
        :
        this.state.unitGroups.length > 0 ? this.state.unitGroups[0] : {}

      await this.setState({ selectedTargetType: e, newData: [], selectedObject: selObj })
      this.findResource(this.state.res)

    }


  }

  onUnitChange(e) {
    if (e) {
      this.setState({ selectedObject: e, selectedTargetType: fileJSON.targetTypes[0] })
    }
  }
  onUnitGroupChange(e) {
    if (e) {
      this.setState({ selectedObject: e, selectedTargetType: fileJSON.targetTypes[1] })
    }
  }

  buildFinalData(colData) {
    if (this.state.selectedTargetType.id === 0) // unit 
    {
      this.buildForUnit(colData);
    }
    if (this.state.selectedTargetType.id === 1) // group 
    {
      // save the response as it in new data
      console.log('in case of group response, new data is : ')
      console.log(colData)
      this.setState({ newData: colData })

      //this.buildForGroup(colData);
    }
    this.setState({executing:false,overlayText:''})
  }
  buildForUnit(colData) {
    this.setState({ newData: [] })
    console.log('start build for unit')
    let arr = this.state.columns
    for (let index = 0; index < colData.length; index++) {
      const dayName = colData[index].c[1]; // get 'day n'
      const kmVal = colData[index].c[2] // get km value
      let targetIndex = arr.findIndex(function (item) {
        return 'Day ' + item.day === dayName

      })
      console.log('Found ' + targetIndex)
      if (targetIndex >= 0) {
        arr[targetIndex] = {
          day: dayName.split(' ')[1],
          km: kmVal
        }
      }

    }


    console.log('finall array data is : ')


    let innerObj = { unitName: this.state.selectedObject.$$user_name, data: arr }
    let nd = this.state.newData
    nd.push(innerObj)
    this.setState({ newData: nd })
    // console.log(nd)
    // let arrrrr = this.transpos(nd);
    // // this.exportCSV(arrrrr)
    // this.exportToCSV(arrrrr, 'test')
  }
  buildForGroup(colData) {

    //  body will be rendered from table components

  }


  async calcCol(month, year) {
    let arr = []
    let n = new Date(year, month, 0).getDate();
    arr.push({ day: 'Unit Name ', km: this.state.selectedTargetType.id === 0 ? this.state.selectedObject.$$user_name : '' })
    for (let index = 0; index < n; index++) {

      if (this.state.selectedTargetType.id === 0) // unit
      {
        //  console.log('calculation for unit')
        //  if(index === 0)
        //  {
        //   arr.push({day:'Unit Name ',km:this.state.selectedObject.$$user_name})
        //  }
        //  else{
        //   arr.push({day:''+(index+1),km:''})
        //  }
        arr.push({ day: '' + (index + 1), km: '' })
      }
      if (this.state.selectedTargetType.id === 1) {
        console.log('calculation for group')
        // if(index === 0)
        // {
        //  arr.push({day:'Unit Name ',km:''})

        // }
        //  arr.push({day:''+(index+1),km:''})
        arr.push({ day: '' + (index + 1), km: '' })
      }

    }
    console.log('structure arrays is : ')
    console.log(arr)
    await this.setState({ columns: arr })
  }
  transpos(oldData) {
    console.log('old data is ')
    let newArr = []
    let object1 = []
    let object2 = []
    let data = oldData[0].data
    for (let index = 0; index < data.length; index++) {

      object1.push(data[index].day);
      object2.push(data[index].km);

    }
    newArr.push(object1)
    newArr.push(object2)

    console.log('new arrrrrray ')
    console.log(newArr)
    return newArr;
  }
  transposForGroup(data) {
    console.log('old data is ')
    let newArr = []
    let newColumns = []
    for (let index = 0; index < this.state.columns.length; index++) {
      newColumns.push(this.state.columns[index].day)
    }

    newArr.push(newColumns)

    for (let index = 0; index < data.length; index++) {
      let arr = []
      arr.push(data[index].c[1]) // get unit name and put it in first col of array

      // then we should iterate through columns and try to find km value of days 
      for (let j = 1; j <= newColumns.length; j++) {
        let itemIndex = data[index].r.findIndex(function (v, i) {
          console.log(v.c[1])
          console.log(newColumns[j])
          return v.c[1] === 'Day ' + newColumns[j] // ex : if Day 1 === Day 1
        })

        if (itemIndex >= 0) {
          arr.push(data[index].r[itemIndex].c[2]) // get km
        }
        else {
          arr.push('') // get km

        }
      }
      newArr.push(arr)
    }
    console.log('final array in case of group for export excel is ')
    console.log(newArr)
    return newArr;
  }
  // exportCSV(rows) {

  //   var universalBOM = "\uFEFF";

  //   let csvContent = "data:text/csv;charset=utf-8," + universalBOM;
  //   console.log('rows is ')
  //   console.log(rows)
  //   rows.forEach(function (rowArray) {
  //     console.log('will join')
  //     console.log(rowArray)
  //     let row = rowArray.join(",");
  //     csvContent += row + "\r\n";
  //   });
  //   var encodedUri = encodeURI(csvContent);
  //   window.open(encodedUri);
  //   this.setState({ encodedUri: encodedUri, download: 'myD.csv' })

  // }


 async exportToCSV(csvData, fileName) {
    console.log('exporting')
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    await FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({exporting:false,overlayText:'', showAlert: true, error: false, alertVariant:'success',alertMSG: 'تم تصدير التقرير بنجاح' })
    

  }
  export(e) {
    console.log('start export the report')
    this.setState({exporting:true,overlayText:'جاري تصدير التقرير...'})

    if (this.state.selectedTargetType.id === 0) {
      if (this.state.newData.length > 0) {
        let arrrrr = this.transpos(this.state.newData);
        this.exportToCSV(arrrrr, 'km_report_' + this.state.fromDateVal.toDateString())
      }
      else {
        this.setState({ showAlert: true, error: true, alertMSG: 'لا يمكن تحميل التقرير, لا يوجد بيانات' })
        return;
      }

    }
    else {
      if (this.state.newData.length > 0) {
        let arrrrr = this.transposForGroup(this.state.newData);
        this.exportToCSV(arrrrr, 'km_report_' + this.state.fromDateVal.toDateString())
      }
      else {
        this.setState({ showAlert: true, error: true, alertMSG: 'لا يمكن تحميل التقرير, لا يوجد بيانات' })
        return;
      }
    }

  }

}

export default App;
