import React from 'react';
import ExeForm from './exeForm'
import Context from './context'
import MyTable from './table'
class Report extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }


    componentDidMount() {
    }

    render() {
        return (
            <Context.Consumer>
                {
                    ctx => {

                        return (
                            <div>
                                <div>نتائج التقرير ستظهر هنا</div>
                                <MyTable style={{ fontFamily: 'Cairo' }}></MyTable>

                            </div>

                        )
                    }
                }
            </Context.Consumer>
        )
    }
}

export default Report;