import React from 'react'
import { Table } from 'semantic-ui-react'
import Context from './context'

function TableExamplePagination(props) {
    return (
        <Context.Consumer>
            {
                ctx => {
                    return (<Table celled className="myTable">
                        <Table.Header>
                            <Table.Row>


                                {

                                    ctx.value.columns.map(function (val, index) {
                                        return (
                                            <Table.HeaderCell key={index}>{val.day}</Table.HeaderCell>
                                        )
                                    })
                                }
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>

                            {
                                ctx.value.selectedTargetType.id === 0 ?

                                    ctx.value.newData.length > 0 ?
                                        ctx.value.newData.map(function (value, index) {
                                            return (
                                                <Table.Row key={index}>
                                                    {

                                                        ctx.value.newData[index].data.map(function (value2, index2) {
                                                            return (


                                                                <Table.Cell style={value2.km.length > 0 ? { backgroundColor: "green", color: "white" } : null} key={index + index2}>{value2.km}</Table.Cell>
                                                            )
                                                        })
                                                    }
                                                </Table.Row>
                                            )
                                        })
                                        :
                                        null

                                    : // if selected type id is 1 means for group
                                    ctx.value.newData.length > 0 ?
                                        ctx.value.newData.map(function (value, index) {
                                            return (
                                                <Table.Row key={index}>
                                                    {

                                                        ctx.value.columns.map(function (value2, index2) {

                                                            let elem = ctx.value.newData[index].r.find(function (item) {

                                                                return item.c[1] === 'Day ' + value2.day

                                                            })

                                                            let element = <Table.Cell key={index + index2}></Table.Cell>
                                                            if (index2 === 0) { // unit name 
                                                                element = <Table.Cell key={index + index2}>{ctx.value.newData[index].c[1]}</Table.Cell>
                                                            }

                                                            if (typeof elem !== 'undefined') {
                                                                element = <Table.Cell style={elem.c[2].length > 0 ? { backgroundColor: "green", color: "white" } : null} key={index + index2}>{elem.c[2]}</Table.Cell>

                                                            }
                                                            return element;
                                                        })
                                                    }
                                                </Table.Row>
                                            )
                                        })
                                        :
                                        null

                            }
                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>

                            </Table.Row>
                        </Table.Footer>
                    </Table>)
                }
            }
        </Context.Consumer>
    )
}

export default TableExamplePagination